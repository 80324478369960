import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private appService: AppService, private router: Router) {}
  canActivate() {
    return this.appService
      .getUserData()
      .pipe(take(1))
      .pipe(
        map((authState) => {
          if (authState) {
            if (this.appService.isAdmin()) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
      );
  }
  
}
